<template>
  <div>
    <div class="mt-32" v-if="isLoading">
      <Loader size="xxs" />
    </div>
    <div class="w-full flex flex-col items-start p-3 gap-4 shadow rounded-b-md">
      <template>
        <div class="w-full flex p-0 justify-between items-center">
          <h6 class="font-bold text-lg leading-normal" v-if="title">
            {{ title }}
          </h6>
          <div class="flex items-center gap-5" v-if="(topButton || commentButton)">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn hover:text-white hover:bg-dynamicBackBtn"
              width="190px"
              v-if="topButton"
            >
              {{ topButton }}
            </Button>
            <slot v-if="(hasCommentModalSlot && commentButton)" name="commentModal" />
            <div @click="handleCommentModal" v-if="commentButton"
              class="flex justify-center items-center gap-2 hover:bg-cultured cursor-pointer p-3 rounded-md"
            >
              <Icon
                class-name="text-darkPurple mx-auto self-center"
                size="xs"
                icon-name="comment-outline"
              />
              <span class="text-base text-darkPurple font-semibold tracking-wide leading-normal">
                {{ commentButton }}
              </span>
            </div>
          </div>
          <div class="w-5/12 flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500" v-if="topInfoBox">
            <Icon
              class-name="text-carrotOrange mx-auto self-center"
              size="xs"
              icon-name="icon-info"
            />
            <span class="flex text-sm mx-3">
              {{ topInfoBox }}
            </span>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col">
        <div class="w-full flex px-3 py-1 g-3 justify-between align-center bg-gray-300 rounded-t-lg">
          <p class="font-black text-xs leading-5 tracking-wide uppercase text-romanSilver">KPA Types</p>
          <p class="font-black text-xs leading-5 tracking-wide uppercase text-romanSilver">Allocated Weight</p>
        </div>
        <div class="w-full py-3 px-2 text-darkPurple">
          <div v-for="(item, i) in items" :key="`${item}-${i}`">
            <div class='relative text-right whitespace-nowrap flex items-center justify-between py-2' @click="handleShowTable(i)" :bind="showTable">
              <div class="absolute left-0 right-0 border border-dashed border-gray-400 h-0 ml-52 mr-32 cursor-pointer" />
              <span class="mr-2 pr-2 bg-white relative float-left font-bold text-lg leading-tight text-left tracking-normal cursor-pointer">
                {{ item.type }}
              </span>
              <div class="relative bg-white p-1 flex gap-5 pl-10 cursor-pointer">
                <div class="bg-gray-100 flex justify-center align-center p-1 gap-2 rounded-sm">
                  <span class="text-darkPurple text-center text-xs uppercase tracking-wide leading-normal font-black w-10">{{ item.score }}%</span>
                </div>
                <Icon
                  class-name="text-darkPurple mx-auto self-center"
                  size="xs"
                  :icon-name="(showTable === i) ? 'chevronDown' : 'chevronRight'"
                />
              </div>
            </div>
            <div class="relative overflow-x-auto" v-if="(showTable === i && item.data)">
              <table class="w-full text-sm text-left text-darkPurple">
                <thead class="font-black text-xs text-romanSilver uppercase h-9 bg-white rounded-t-md leading-4">
                  <tr class="text-romanSilver">
                    <th scope="col" class="px-6 py-0 h-4">KPA</th>
                    <th scope="col" class="px-6 py-0 h-4">OBJECTIVE</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center">KPI</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center">weight</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center">performance outcome</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center" v-if="item.data.some((_) => _.selfRating)">self rating</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center" v-if="item.data.some((_) => _.l1ManagerRating)">l1 manager's rating</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center" v-if="item.data.some((_) => _.l2ManagerRating)">l2 manager's rating</th>
                    <th scope="col" class="px-6 py-0 h-4 text-center" v-if="item.data.some((_) => _.appraisalRating)">Appraisal</th>
                  </tr>
                  <tr class="p-0 h-6 !bg-cultured">
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" />
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" />
                    <th scope="row" class="px-2 py-1 whitespace-nowrap bg-cultured">
                      <table class="w-full bg-cultured">
                        <tr class="w-full">
                          <td class="p-0 px-2 font-bold text-sm leading-4 tracking-wide text-darkPurple">0</td>
                          <td class="p-0 px-2 font-bold text-sm leading-4 tracking-wide text-darkPurple">1</td>
                          <td class="p-0 px-2 font-bold text-sm leading-4 tracking-wide text-darkPurple">2</td>
                          <td class="p-0 px-2 font-bold text-sm leading-4 tracking-wide text-darkPurple">3</td>
                          <td class="p-0 px-2 font-bold text-sm leading-4 tracking-wide text-darkPurple">4</td>
                        </tr>
                      </table>
                    </th>
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" />
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" />
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" v-if="item.data.some((_) => _.selfRating)">
                      <table class="w-full text-darkPurple">
                        <tr class="text-darkPurple">
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">rating</td>
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">score</td>
                        </tr>
                      </table>
                    </th>
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" v-if="item.data.some((_) => _.l1ManagerRating)">
                      <table class="w-full text-darkPurple">
                        <tr class="text-darkPurple">
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">rating</td>
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">score</td>
                        </tr>
                      </table>
                    </th>
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" v-if="item.data.some((_) => _.l2ManagerRating)">
                      <table class="w-full text-darkPurple">
                        <tr class="text-darkPurple">
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">rating</td>
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">score</td>
                        </tr>
                      </table>
                    </th>
                    <th scope="col" class="px-2 py-1 whitespace-nowrap bg-cultured" v-if="item.data.some((_) => _.appraisalRating)">
                      <table class="w-full text-darkPurple">
                        <tr class="text-darkPurple">
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">rating</td>
                          <td class="p-0 font-bold text-sm text-center capitalize leading-4 tracking-wide text-darkPurple">score</td>
                        </tr>
                      </table>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-cultured p-0">
                  <tr class="bg-white border-b border-dashed bg-table" v-for="(item, i) in item.data" :key="`${item}-${i}`">
                    <td
                      v-if="item.kpa"
                      scope="row"
                      class="bg-white pr-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      {{ item.kpa }}
                    </td>
                    <td
                      v-if="item.objective"
                      scope="row"
                      class="bg-white pr-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      {{ item.objective }}
                    </td>
                    <td
                      v-if="item.kpi"
                      scope="row"
                      class="bg-white px-0 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <table class="w-full">
                        <tr class="w-full">
                          <td class="p-0 px-3" v-for="(item, i) in item.kpi" :key="`${item}-${i}`">
                            {{ item }}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      v-if="item.weight"
                      scope="row"
                      class="bg-white px-6 py-4 text-center whitespace-nowrap text-base font-normal leading-5 text-blueCrayola"
                    >
                      {{ item.weight }}%
                    </td>
                    <td
                      scope="row"
                      class="bg-white px-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <input
                        :value="item.outcome"
                        class="bg-white border border-romanSilver text-gray-900 w-64 h-10 p-2.5 rounded-md"
                        placeholder="--Enter--"
                      >
                    </td>
                    <td
                      v-if="item.selfRating"
                      scope="row"
                      class="bg-white px-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <table class="w-full">
                        <tr>
                          <td class="p-1">
                            <input
                              :value="item.selfRating.rating"
                              type="number"
                              class="w-28 bg-white border border-romanSilver text-gray-900 h-10 p-2.5 rounded-md"
                              placeholder="---"
                            >
                          </td>
                          <td class="p-1">
                            <Button
                              class="text-romanSilver border border-romanSilver bg-cultured"
                              width="112px"
                              height="40px"
                            >
                              {{ item.selfRating.score }}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </td>
                     <td
                      v-if="item.l1ManagerRating"
                      scope="row"
                      class="bg-white px-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <table class="w-full">
                        <tr>
                          <td class="p-1">
                            <input
                              :value="item.l1ManagerRating.rating"
                              type="number"
                              class="w-28 bg-white border border-romanSilver text-gray-900 h-10 p-2.5 rounded-md"
                              placeholder="---"
                            >
                          </td>
                          <td class="p-1">
                            <Button
                              class="text-romanSilver border border-romanSilver bg-cultured"
                              width="112px"
                              height="40px"
                            >
                              {{ item.l1ManagerRating.score }}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      v-if="item.l2ManagerRating"
                      scope="row"
                      class="bg-white px-6 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <table class="w-full">
                        <tr>
                          <td class="p-1">
                            <input
                              :value="item.l2ManagerRating.rating"
                              type="number"
                              class="w-28 bg-white border border-romanSilver text-gray-900 h-10 p-2.5 rounded-md"
                              placeholder="---"
                            >
                          </td>
                          <td class="p-1">
                            <Button
                              class="text-romanSilver border border-romanSilver bg-cultured"
                              width="112px"
                              height="40px"
                            >
                              {{ item.l2ManagerRating.score }}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      v-if="item.appraisalRating"
                      scope="row" class="bg-white px-0 py-0 whitespace-nowrap text-base font-normal leading-5 text-darkPurple"
                    >
                      <table class="w-full">
                        <tr>
                          <td class="p-1">
                            <input
                              :value="item.appraisalRating.rating"
                              type="number"
                              class="w-28 bg-white border border-romanSilver text-gray-900 h-10 p-2.5 rounded-md"
                              placeholder="---"
                            >
                          </td>
                          <td class="p-1">
                            <Button
                              class="text-romanSilver border border-romanSilver bg-cultured"
                              width="112px"
                              height="40px"
                            >
                              {{ item.appraisalRating.score }}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr class="bg-white bg-table pt-0" style="border-bottom: 1px solid #E5E5E5" v-if="item.totalScores">
                    <td scope="col" class="px-2 py-1 whitespace-nowrap bg-white" />
                    <td scope="col" class="px-2 py-1 whitespace-nowrap bg-white" />
                    <td scope="col" class="px-2 py-1 whitespace-nowrap bg-white" />
                    <td scope="col" class="px-2 py-1 whitespace-nowrap bg-white">
                      <div class="flex justify-center px-2">
                        <span class="text-darkPurple border border-solid border-white" v-if="item.totalScores.weight">
                          {{ item.totalScores.weight }}
                        </span>
                        <span class="text-darkPurple border border-solid border-white" v-else>
                          ---
                        </span>
                      </div>
                    </td>
                    <td class="text-right pt-0 bg-white">
                      <span class="pr-5 pt-0 font-bold text-lg h-5 leading-5 text-darkPurple">
                        Total Scores
                      </span>
                    </td>
                    <td class="text-right pr-5 py-2 font-bold text-lg h-5 leading-5 text-darkPurple bg-white" v-if="item.data.some((_) => _.selfRating)">
                      <div class="flex justify-end px-2">
                        <Button class="text-romanSilver border border-solid border-white bg-ghostWhite" width="112px" height="40px">
                          <span v-if="item.totalScores.selfRating">{{ item.totalScores.selfRating }}</span>
                          <span v-else>---</span>
                        </Button>
                      </div>
                    </td>
                    <td class="text-right pr-5 py-2 font-bold text-lg h-5 leading-5 text-darkPurple bg-white" v-if="item.data.some((_) => _.l1ManagerRating)">
                      <div class="flex justify-end px-2">
                        <Button class="text-romanSilver border border-solid border-white bg-ghostWhite" width="112px" height="40px">
                          <span v-if="item.totalScores.l1ManagerRating">{{ item.totalScores.l1ManagerRating }}</span>
                          <span v-else>---</span>
                        </Button>
                      </div>
                    </td>
                    <td class="text-right py-2 pr-6 font-bold text-lg h-5 leading-5 text-darkPurple bg-white" v-if="item.data.some((_) => _.l2ManagerRating)">
                      <div class="flex justify-end">
                        <Button class="text-romanSilver border border-solid border-white bg-ghostWhite" width="112px" height="40px">
                          <span v-if="item.totalScores.l2ManagerRating">{{ item.totalScores.l2ManagerRating }}</span>
                          <span v-else>---</span>
                        </Button>
                      </div>
                    </td>
                    <td class="text-right py-2 pr-0 font-bold text-lg h-5 leading-5 text-darkPurple bg-white" v-if="item.data.some((_) => _.appraisalRating)">
                      <div class="flex justify-end">
                        <Button class="text-romanSilver border border-solid border-white bg-ghostWhite" width="112px" height="40px">
                          <span v-if="item.totalScores.appraisalRating">{{ item.totalScores.appraisalRating }}</span>
                          <span v-else>---</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="hasFinalScoreSection">
      <div class="w-full flex p-3 gap-4 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap">
        <span class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider">Final Scores</span>
        <div class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52" v-if="borderline === 'solid'" />
        <div class="absolute left-0 right-0 border border-dashed border-gray-400 h-0 ml-36 mr-52" v-if="borderline === 'dashed'" />
        <div class="flex justify-end gap-5 bg-white relative px-5">
          <div class="flex flex-col items-start" v-if="selfAppraisalScore">
            <span class="text-gray-400 text-xs text-center font-black leading-5 tracking-wider uppercase">Self - Appraisal</span>
            <span class="font-extrabold custom-text-22 custom-line-26 text-darkPurple custom-spacing-0015">{{ selfAppraisalScore }}</span>
          </div>
          <div class="flex flex-col items-start" v-if="lineManagerScoreOne">
            <span class="text-gray-400 text-xs text-center font-black leading-5 tracking-wider uppercase">Line Manager 1</span>
            <span class="font-extrabold custom-text-22 custom-line-26 text-darkPurple custom-spacing-0015">{{ lineManagerScoreOne }}</span>
          </div>
          <div class="flex flex-col items-start" v-if="lineManagerScoreTwo">
            <span class="text-gray-400 text-xs text-center font-black leading-5 tracking-wider uppercase">Line Manager 2</span>
            <span class="font-extrabold custom-text-22 custom-line-26 text-darkPurple custom-spacing-0015">{{ lineManagerScoreTwo }}</span>
          </div>
          <div class="flex flex-col items-start" v-if="approvedScore">
            <span class="text-gray-400 text-xs text-center font-black leading-5 tracking-wider uppercase">Approved Score</span>
            <span class="font-extrabold custom-text-22 custom-line-26 text-blueCrayola custom-spacing-0015">{{ approvedScore }}</span>
          </div>
          <div class="flex flex-col items-start" v-if="appraisalFlag">
            <span class="text-gray-400 text-xs text-center font-black leading-5 tracking-wider uppercase">Appraisal Flag</span>
            <span class="font-semibold text-base custom-line-26 text-darkPurple custom-spacing-0015">{{ appraisalFlag }}</span>
          </div>
        </div>
      </div>
    </template>
    <div class="w-full flex p-3 gap-4 justify-between items-center" v-if="hasFooterSection">
      <div class="flex justify-between w-4/12 gap-2">
        <Button
          :background="appearance.buttonColor || defaultBTNColor"
           class="text-white" width="190px" v-if="bottomButton">
          {{ bottomButton }}
        </Button>
        <Button class="text-dynamicBackBtn border border-solid border-dynamicBackBtn" width="126px" v-if="draftButton">{{ draftButton }}</Button>
        <Button class="text-gray-400" width="126px" v-if="cancelButton">{{ cancelButton }}</Button>
      </div>
      <div class="w-5/12 flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500" v-if="bottomInfoBox">
        <Icon
          class-name="text-carrotOrange mx-auto self-center"
          size="xs"
          icon-name="icon-info"
        />
        <span class="flex text-sm mx-3">
          {{ bottomInfoBox }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
  import Loader from "@/components/Loader";
  import Icon from "@/components/Icon";
  import Button from "@/components/Button";


export default {
  name: "KeyPerformanceArea",
  components: {
    Button,
    Loader,
    Icon
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    topButton: {
      type: String,
      required: false,
      default: 'Submit'
    },
    commentButton: {
      type: String,
      required: false,
      default: 'Comment'
    },
    borderline: {
      type: String,
      required: false,
      default: 'solid'
    },
    selfAppraisalScore: {
      type: String,
      required: false,
      default: ''
    },
    lineManagerScoreOne: {
      type: String,
      required: false,
      default: ''
    },
    lineManagerScoreTwo: {
      type: String,
      required: false,
      default: ''
    },
    approvedScore: {
      type: String,
      required: false,
      default: ''
    },
    appraisalFlag: {
      type: String,
      required: false,
      default: ''
    },
    bottomButton: {
      type: String,
      required: false,
      default: 'Share'
    },
    draftButton: {
      type: String,
      required: false,
      default: 'Save Draft'
    },
    cancelButton: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    topInfoBox: {
      type: String,
      required: false,
    },
    bottomInfoBox: {
      type: String,
      required: false,
    },
    hasFooterSection: {
      type: Boolean,
      required: false,
      default: false
    },
    hasFinalScoreSection: {
      type: Boolean,
      required: false,
      default: false
    },

  },
  data(){
    return {
      showTable: false,
    }
  },
  methods: {
    handleShowTable(index){
      if (this.showTable === index) this.showTable = -1;
      else this.showTable = index;
    },
    handleCommentModal(){
      this.commentModal = !this.commentModal
    }
  },
  computed: {
    hasCommentModalSlot() {
      return !!this.$slots.commentModal;
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
};
</script>